import axios from 'axios'

const API = '/x-configuraciones'

const XConfiguraciones = {
    obtener(tipo){
        return axios(`${API}/tipo/${tipo}`)
    },
    diasAdmon(){
        return axios(`${API}/dias-admon`)
    },
    guardar(payload){
        return axios.post(`${API}/dias-admon`,payload)
    },
    guardarConfiguracion(payload){
        return axios.post(`${API}`,payload)
    },
}

export default XConfiguraciones
