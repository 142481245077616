<template>
    <section class="config-fecha-pago ml-4">
        <p class="f-20 f-500">Fechas de pago de administración</p>
        <ValidationObserver tag="div" class="mt-4" ref="form">
            <div class="row mb-4">
                <div class="col-6">
                    <ValidationProvider tag="div" rules="required|numeric|min_value:-127|max_value:127" v-slot="{ errors }" name="valor administración">
                        <p class="f-12 pl-2 label-inputs">Día máximo de pago de cada mes</p>
                        <el-input-number placeholder="Día" :controls="false" class="w-100" v-model="admon[0].numero" size="small"/>
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-6">
                    <ValidationProvider tag="div" rules="required|numeric|min_value:-127|max_value:127" v-slot="{ errors }" name="día máximo">
                        <p class="f-12 pl-2 label-inputs">Día máximo para pronto pago</p>
                        <el-input-number placeholder="Día" :controls="false" class="w-100" v-model="admon[1].numero" size="small"/>
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-6">
                    <ValidationProvider tag="div" rules="required|numeric|min_value:-127|max_value:127" v-slot="{ errors }" name="cantidad de meses">
                        <p class="f-12 pl-2 label-inputs">Cantidad de meses por adelantado</p>
                        <el-input-number placeholder="Cantidad" :controls="false" class="w-100" v-model="admon[2].numero" size="small"/>
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
            </div>
            <!-- btn guardar -->
            <div class="row mt-2 mt-sm-4">
                <div class="col-12">
                    <button class="btn btn-general f-14 f-300 px-3" @click="guardar">Guardar</button>
                </div>
            </div>
        </ValidationObserver>
        <!-- Partials -->
        <modal-set-fecha-implementacion ref="abrirSetFechaImplementacion" />
    </section>
</template>
<script>
import XConfiguraciones from '~/services/xconfiguraciones'
export default {
    components:{
        modalSetFechaImplementacion: () => import('./partials/modalSetFechaImplementacion')
    },
    data(){
        return{
            loading: true,
            admon:[
              {
                  tipo: 11,
                  numero: 0
              },
              {
                  tipo: 12,
                  numero: 0
              },
              {
                  tipo: 14,
                  numero: 0
              }
            ]
        }
    },
    created(){
      this.obtenerInfo()
    },
    methods:{
        guardarFechasPago(){
            
        },
        async obtenerInfo(){
          try {
            this.loading = true
            const {data} = await XConfiguraciones.diasAdmon()
            this.admon = data.data
            this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
          }          
        },
        async guardar(){
          try {

            let validate = await this.$refs.form.validate()
            if(!validate) return this.notificacion('','Por favor completa los campos')

            this.loading = true
            let payload={
              data : this.admon
            }
            const {data} = await XConfiguraciones.guardar(payload)
            if(data.success){
              this.obtenerInfo()
              this.$notify({
                title: 'Fechas de Pago',
                message: data.mensaje,
                type: 'success'
              });
            }
            this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
              this.$notify({
                title: 'Fechas de Pago',
                message: 'error guardando',
                type: 'warning'
              });
          }          
        },
        
    }
}
</script>
<style lang="scss" scoped>
.config-fecha-pago{
    .btn-general{
        min-width: 136px;
        height: 32px;
    }
}
</style>